import axios from "axios"
let vlogin_id = localStorage.getItem('login_id');
class ApMarketingService {


    list_marketing_banner() {
        vlogin_id = localStorage.getItem('login_id');
        let form = {login_id:vlogin_id};
        return axios.post("ap_marketing/list_marketing_banner", form);

    }
    

    insert_marketing_banner(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_marketing/insert_marketing_banner", form);

    }        

    view_marketing_banner(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_marketing/view_marketing_banner", form);

    }        

    update_marketing_banner(form) {
        vlogin_id = localStorage.getItem('login_id');
        form.login_id = vlogin_id;
        return axios.post("ap_marketing/update_marketing_banner", form);

    }        

    
}
    
 

export default new ApMarketingService();